import { useEffect } from "react";
import withRouter from "app/utils/hocs/withRouter";
import Home from "app/pages/Home/Home";
import { connect } from "react-redux";
import { sendTagOnHomePageLoad } from "app/utils/analytics";
import {
	getBrandDisplayName,
	getPartnerCode,
	getPartnerMarketingHomeData,
} from "app/reducers/partnerSelector";
import { getCurrentShop } from "app/reducers/shopSelector";

const HomeContainer = props => {
	useEffect(() => {
		sendTagOnHomePageLoad();
	}, []);

	return <Home {...props} />;
};

const mapStateToProps = state => {
	return {
		onGoingProductCount: state.products.length,
		resolution: state.resolution,
		brand: state.brand.code,
		home: getPartnerMarketingHomeData(state),
		shop: getCurrentShop(state),
		partnerCode: getPartnerCode(state),
		productsVisibility: state.productsVisibility,
		brandDisplayName: getBrandDisplayName(state),
	};
};

export default withRouter(connect(mapStateToProps)(HomeContainer));
