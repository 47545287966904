import PropTypes from "prop-types";
import { Fragment, memo } from "react";
import { ficheProduitPropTypes, resolutionPropType } from "app/utils/propTypes";
import HomeDesktop from "@brand/Home/desktop/HomeDesktop";
import HomeMobile from "@brand/Home/mobile/HomeMobile";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import ReasonToBookBlockContainer from "app/pages/.shared/ReasonsToBook/ReasonToBookBlock/ReasonToBookBlockContainer";
import "./Home.scss";
import { useMatch } from "react-router-dom";
import HeadMetadata from "app/utils/metas/HeadMetadata";

const Home = props => {
	const { resolution } = props;

	const loginMatch = useMatch({
		path: "/home/login",
		caseSensitive: true,
	});

	const signupMatch = useMatch({
		path: "/home/signup",
		caseSensitive: true,
	});

	return (
		<Fragment>
			{loginMatch && (
				<HeadMetadata
					description="page.description.home.login"
					title="page.title.home.login"
					robotsMetaTag="index, follow"
				/>
			)}
			{signupMatch && (
				<HeadMetadata
					description="page.description.home.signup"
					title="page.title.home.signup"
					robotsMetaTag="noindex, follow"
				/>
			)}

			{resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM ? (
				<HomeMobile {...props} />
			) : (
				<HomeDesktop {...props} />
			)}

			<div className="home__rtb">
				<ReasonToBookBlockContainer />
			</div>
		</Fragment>
	);
};

Home.propTypes = {
	auth: PropTypes.element,
	shop: PropTypes.string,
	onGoingProductCount: PropTypes.number,
	resolution: resolutionPropType,
	products: PropTypes.arrayOf(ficheProduitPropTypes),
	brandDisplayName: PropTypes.string,
};

export default memo(Home);
