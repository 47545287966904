import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";
import PropTypes from "prop-types";
import { memo, useContext } from "react";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./SmartDPHome.scss";
import LoginContainer from "app/pages/Auth/Signin/LoginContainer";
import { FormattedMessage } from "react-intl";
import SignupContainer from "app/pages/Auth/Signup/SignupContainer";
import ChangePasswordContainer from "app/pages/Auth/ChangePassword/ChangePasswordContainer";
import ResetPasswordContainer from "app/pages/Auth/ResetPassword/ResetPasswordContainer";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import ProductListBlockContainer from "app/pages/.shared/DynamicBlocks/blocks/ProductListBlock/ProductListBlockContainer";

const IMG_SIZES = {
	medium: { width: 750, height: 320 },
	small: { width: 750, height: 320 },
	large: { width: 2880 },
	xlarge: { width: 2880 },
};

const SmartDPHome = ({ home = {} }) => {
	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const images = home.images;
	const location = useLocation();

	const AuthForm = (
		<Routes>
			<Route
				path="/login"
				element={
					<LoginContainer
						signinButtonLabel={<FormattedMessage id="home.header.button.signin.now" />}
						headline={false}
						showTopFacebook={true}
					/>
				}
			/>
			<Route
				path="/signup"
				element={<SignupContainer headline={false} showTopFacebook={true} />}
			/>
			<Route
				path="/changepassword"
				element={<ChangePasswordContainer location={location} />}
			/>
			<Route path="/resetpassword" element={<ResetPasswordContainer />} />
			<Route path="/*" element={<Navigate to="./signup" />} />
		</Routes>
	);

	const showMarketingText =
		(isMobile && home.images?.textMobile) || (!isMobile && home.images?.textDesktop);

	return (
		<div className="sdp-home">
			<div className="sdp-home__hero">
				<BackgroundProgressiveImg
					sizes={IMG_SIZES}
					resolution={resolution}
					backgroundPosition={"right"}
					thumbnail={
						isMobile ? images?.imageMobileThumbnail : images?.imageDesktopThumbnail
					}
					src={isMobile ? images?.imageMobile : images?.imageDesktop}
				/>
				<div className="sdp-home__hero-content">
					<div className="sdp-home__marketing">
						<div className="sdp-home__marketing-container">
							<Routes>
								{showMarketingText && (
									<Route
										path="/login"
										element={
											<img
												alt="login marketing text"
												className="sdp-home__marketing-text"
												src={
													isMobile
														? home.images?.textMobile
														: home.images?.textDesktop
												}
											/>
										}
									/>
								)}

								{showMarketingText && (
									<Route
										path="/signup"
										element={
											<img
												alt="signup marketing text"
												className="sdp-home__marketing-text"
												src={
													isMobile
														? home.images?.textMobile
														: home.images?.textDesktop
												}
											/>
										}
									/>
								)}

								<Route
									path="/changepassword"
									element={
										<Typography
											variant={TYPOGRAPHY_VARIANTS.XL3}
											variantMD={TYPOGRAPHY_VARIANTS.XL4}
											isBold
											component="h1"
										>
											<FormattedMessage id="auth.reset.password.marketing.text" />
										</Typography>
									}
								/>

								<Route
									path="/resetpassword"
									element={
										<Typography
											variant={TYPOGRAPHY_VARIANTS.XL3}
											variantMD={TYPOGRAPHY_VARIANTS.XL4}
											isBold
											component="h1"
										>
											<FormattedMessage id="auth.reset.password.marketing.text" />
										</Typography>
									}
								/>
							</Routes>
						</div>
					</div>
					{!isMobile && <div className="sdp-home__form">{AuthForm}</div>}
				</div>
			</div>
			{isMobile && <div className="sdp-home__form">{AuthForm}</div>}
			<div className="sdp-home__blocks">
				<div className="sdp-home__blocks-item">
					<ProductListBlockContainer shortVersion />
				</div>
			</div>
		</div>
	);
};

SmartDPHome.propTypes = {
	home: PropTypes.object,
};

export default memo(SmartDPHome);
